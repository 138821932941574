import * as React from "react";
import Info from "./Info";
import T1 from "./T1";
import T2 from "./T2";

class T3 extends React.Component<{}, {showInfo:boolean, n:number}> {
    static n = 0;

    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false,
            n: T3.n
        };
    }

    componentDidMount(): void {
        this.setState({n: ++T3.n});
    }

    render() {
        return (
            <h1
                className='text-justify font-weight-bold'
                onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Títulos das seções terciárias: numerados, justificados e negrito
                </Info>
                {T1.n}.{T2.n}.{this.state.n} {this.props.children}
            </h1>
        )
    }
}

export default T3;