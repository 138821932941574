import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import Capa from "./Capa";
import FolhaDeRosto from "./FolhaDeRosto";
import Errata from "./Errata";
import FolhaDeAprovacao from "./FolhaDeAprovacao";
import Page from "./Page";
import Tc from "./Tc";
import Citation from "./Citation";
import Menu from "./Menu";
import P from "./P";
import T1 from "./T1";
import T2 from "./T2";
import T3 from "./T3";
import T4 from "./T4";
import T5 from "./T5";
import Referencias from "./Referencias";

function App() {
    const [showRomanticMessage, setShowRomanticMessage] = useState(false)
    const [typedString, setTypedString] = useState('')

    const keyUpListener = (e: KeyboardEvent) => {
        setTypedString(current => current + e.key)
    }

    useEffect(() => {
        if (typedString.length >= 21) {
            if (typedString.match('mateus dumont fadigas')) {
                setShowRomanticMessage(true)
            } else {
                setTypedString('')
            }
        }

        console.log(typedString, typedString.length)
    }, [typedString])

    useEffect(() => {
        document.addEventListener('keyup', keyUpListener)
    }, [])

    return (
        <div>
            {
                showRomanticMessage &&
                <div id="te-amo-baby" onClick={() => setShowRomanticMessage(false)}>
                    <div>
                        Para Mateus Dumont Fadigas: <br/><br/>
                        Mesmo quando estou concentrando programando, <br/>
                        você não sai de meus pensamentos!<br/><br/>
                        Te amo, baby! 💕💕💕<br/><br/>
                        De seu nerd favorito.
                    </div>
                </div>
            }
            <Menu/>
            <a id='contact' href='https://davi.solutions/contato' title='Entrar em contato' target='_blank' rel='noopener noreferrer'>
                Dúvidas ou sugestões? Me envie uma mensagem!
            </a>

            <Capa/>
            <FolhaDeRosto/>
            <Errata/>
            <FolhaDeAprovacao/>
            <div id='dedicatoria'>
                <div className='page-info'>
                    <p className='text-center'>Dedicatória (elemento obrigatório)</p>
                    <p className='text-justify'>
                        Tradicionalmente a dedicatória pode ser escrita na porção inferior da página, alinhada à
                        direita. Porém, a NBR 14724 (ABNT, 2011) não detalha tal formato. Por isso, recomenda-se o
                        formato de parágrafo padrão (como exemplificado na p. 14).
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <P>Dedico esse trabalho a...</P>
                </Page>
            </div>
            <div id='agradecimentos'>
                <div className='page-info'>
                    <p className='text-center'>Agradecimentos (elemento opcional)</p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Agradecimentos</Tc>
                    <P>Agradeço a...</P>
                    <P>Agradeço também...</P>
                    <P>Por fim, meus agradecimentos a...</P>
                </Page>
            </div>
            <div id='epigrafe'>
                <div className='page-info'>
                    <p className='text-center'>Epígrafe (elemento opcional)</p>
                    <p className='text-justify'>
                        Quando utilizada, a epígrafe do trabalho e/ou as epígrafes dos capítulos/seções primárias devem
                        ser formatadas conforme a NBR 10520 (ABNT, 2002), ou seja, como qualquer citação (geralmente
                        longa) e, por isso, a referência completa deve constar na lista de referências. As NBR não dão
                        informação sobre o alinhamento vertical da epígrafe. Por isso, embora tradicionalmente alinhada
                        na parte inferior da página, recomenda-se o alinhamento no topo, como no início de
                        qualquer seção textual.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Citation>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum. (LOREM..., ano, p. número da página)
                    </Citation>
                </Page>
            </div>
            <div id='resumo'>
                <div className='page-info'>
                    <p className='text-center'><strong>Resumo</strong> (elemento obrigatório)</p>
                    <p className='text-justify'>
                        Conforme NBR 6028 (ABNT, 2021), o resumo deve ter entre 150 e 500 palavras, parágrafo único, na voz ativa e
                        terceira pessoa do singular. Evitar siglas, fórmulas e equações. Note que a partir da edição de 2021, as palavras-chave são separadas por ponto e vírgula, conforme exemplo abaixo.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Resumo</Tc>
                    <p className='abstract'>
                        O resumo deve ser escrito conforme a NBR 6028 (ABNT, 2021). Deve ser escrito na primeira pessoa
                        do singular e na voz ativa (e.g. "O presente trabalho discute...").
                    </p>
                    <p>Palavras-chave: palavra-chave 1; palavra-chave 2; palavra-chave 3.</p>
                </Page>
            </div>
            <div id='abstract'>
                <div className='page-info'>
                    <p className='text-center'><strong>Resumo em língua estrangeira</strong> (elemento obrigatório)</p>
                    <p className='text-justify'>
                        Tradução literal do resumo em uma língua estrangeira. O título deve ser o correspondente a
                        "Resumo" na língua estrangeira escolhida (por exemplo, "Abstract" em inglês, "Resumen" em espanhol).
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Abstract</Tc>
                    <p className='abstract'>
                        Translation of the "Resumo" text in a foreign language.
                    </p>
                    <p>Keywords: keyword 1; keyword 2; keyword 3.</p>
                </Page>
            </div>
            <div id='lista-de-figuras'>
                <div className='page-info'>
                    <p className='text-center'>Lista de ilustrações (elemento opcional)</p>
                    <p className='text-justify'>
                        Quando utilizada, deve haver listas separadas para cada tipo de ilustração (por exemplo, "Lista de
                        quadros", "Lista de gráficos", "Lista de mapas", "Lista de fotografias", etc.), cada uma em uma
                        página separada.
                    </p>
                    <p className='text-justify'>
                        Pode ser feita automaticamente no Microsoft Word e em outros editores de texto.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Lista de Figuras</Tc>
                    <table>
                        <tbody>
                            <tr>
                                <td>Figura 1 - </td>
                                <td>Legenda da primeira figura..............................................................</td>
                                <td>página</td>
                            </tr>
                            <tr>
                                <td>Figura 2 - </td>
                                <td>Legenda da segunda figura.............................................................</td>
                                <td>página</td>
                            </tr>
                        </tbody>
                    </table>
                </Page>
            </div>
            <div id='lista-de-tabelas'>
                <div className='page-info'>
                    <p className='text-center'>Lista de tabelas (elemento opcional)</p>
                    <p className='text-justify'>
                        Vale destacar que tabelas devem seguir as Normas de Apresentação Tabular do IBGE (1993).
                    </p>
                    <p className='text-justify'>
                        Pode ser feita automaticamente no Microsoft Word e em outros editores de texto.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Lista de Tabelas</Tc>
                    <table>
                        <tbody>
                            <tr>
                                <td>Tabela 1 - </td>
                                <td>Título da primeira tabela.................................................................</td>
                                <td>página</td>
                            </tr>
                            <tr>
                                <td>Tabela 2 - </td>
                                <td>Título da segunda tabela................................................................</td>
                                <td>página</td>
                            </tr>
                        </tbody>
                    </table>
                </Page>
            </div>
            <div id='lista-de-abreviaturas-e-siglas'>
                <div className='page-info'>
                    <p className='text-center'>Lista de abreviaturas e siglas (elemento opcional)</p>
                    <p className='text-justify'>
                        Siglas utilizadas com muita frequência no texto devem ser inseridas em uma lista como no exemplo
                        abaixo.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Lista de abreviaturas e siglas</Tc>
                    <table>
                        <tbody>
                            <tr>
                                <td>ABNT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                <td>Associação Brasileira de Normas Técnicas</td>
                            </tr>
                            <tr>
                                <td>IBGE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                <td>Instituto Brasileiro de Geografia e Estatística</td>
                            </tr>
                        </tbody>
                    </table>
                </Page>
            </div>
            <div id='lista-de-simbolos'>
                <div className='page-info'>
                    <p className='text-center'>Lista de símbolos (elemento opcional)</p>
                    <p className='text-justify'>
                        Símbolos utilizados com muita frequência no texto devem ser inseridos em uma lista de símbolos,
                        como no exemplo abaixo.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Lista de símbolos</Tc>
                    <table>
                        <tbody>
                            <tr>
                                <td><em>μ</em> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                <td>Média de valor da população</td>
                            </tr>
                            <tr>
                                <td><em>δ<sup>2</sup></em> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                <td>Variância de valor da população</td>
                            </tr>
                            <tr>
                                <td><em>ρ<sub>X, Y</sub></em> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                <td>Correlação entre as variáveis <em>X</em> e <em>Y</em></td>
                            </tr>
                        </tbody>
                    </table>
                </Page>
            </div>
            <div id='sumario'>
                <div className='page-info'>
                    <p className='text-center'><strong>Sumário</strong> (elemento obrigatório)</p>
                    <p className='text-justify'>
                        Deve ser feito conforme NBR 6027 (ABNT, 2012) e também pode ser gerado automaticamente no
                        Microsoft Word e outros editores de texto.
                    </p>
                    <p className='text-justify'>
                        Note que os elementos pós-textuais, ou seja, aqueles que aparecem após a conclusão, não têm
                        numeração no título e devem ser alinhados conforme o exemplo.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Sumário</Tc>
                    <table>
                        <tbody>
                            <tr className='text-uppercase font-weight-bold'>
                                <td>1</td>
                                <td>Introdução...........................................................................................</td>
                                <td>14</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td>2</td>
                                <td>Desenvolvimento...............................................................................</td>
                                <td>15</td>
                            </tr>
                            <tr className='text-uppercase'>
                                <td>2.1</td>
                                <td>Exemplo de título de seção secundária..................................</td>
                                <td>15</td>
                            </tr>
                            <tr className='font-weight-bold'>
                                <td>2.1.1</td>
                                <td>Exemplo de título de seção terciária......................................................</td>
                                <td>15</td>
                            </tr>
                            <tr className='font-italic'>
                                <td>2.1.1.1</td>
                                <td>Exemplo de título de seção quaternária....................................................</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>2.1.1.1.1</td>
                                <td>Exemplo de título de seção quinária..........................................................</td>
                                <td>15</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td>3</td>
                                <td>Considerações finais.......................................................................</td>
                                <td>16</td>
                            </tr>
                            <tr className='text-uppercase'>
                                <td>3.1</td>
                                <td>Limitações e sugestões para pesquisas futuras.................</td>
                                <td>16</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td/>
                                <td>Referências.........................................................................................</td>
                                <td>17</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td/>
                                <td>Glossário..............................................................................................</td>
                                <td>18</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td/>
                                <td>Apêndice A - Título do primeiro apêndice................................</td>
                                <td>19</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td/>
                                <td>Anexo A - Título do primeiro anexo............................................</td>
                                <td>20</td>
                            </tr>
                            <tr className='text-uppercase font-weight-bold'>
                                <td/>
                                <td>Índice.......................................................................................................</td>
                                <td>21</td>
                            </tr>
                        </tbody>
                    </table>
                </Page>
            </div>
            <div id='introducao'>
                <div className='page-info'>
                    <p className='text-center'><strong>Introdução</strong></p>
                    <p className='text-justify'>
                        A Introdução é o primeiro elemento textual do trabalho. A partir da primeira página da
                        Introdução, as páginas devem ser numeradas, conforme exemplo. A contagem das páginas é feita a
                        partir da folha de rosto (i. e. como se a capa fosse a "página 0").
                    </p>
                </div>
                <Page showPageNumber={true}>
                    <T1>Introdução</T1>
                    <P>
                        Após o sumário, último elemento pré-textual, encontram-se os elementos textuais. Os elementos
                        textuais podem ser divididos de diversas formas, mas deve conter, no mínimo, uma parte
                        introdutória (que pode ou não ter o título “Introdução”, variando conforme a área de estudo), um
                        desenvolvimento, que pode, e geralmente é, formado por mais de uma seção primária (por exemplo,
                        “Referencial Teórico”, “Método”, “Resultados e Discussões”), e uma conclusão, que pode ser
                        intitulada de outras formas (por exemplo, “Considerações finais”). Nas próximas páginas são apresentados
                        exemplos de formatações necessárias para elementos comumente utilizadas na parte textual do
                        trabalho.
                    </P>
                    <P>
                        O estilo de parágrafo não é detalhado na NBR 14724 (ABNT, 2011). O espaçamento entrelinhas deve ser de
                        1,5pt, mas o recuo de 1,25cm ou 1,5cm na primeira linha não faz parte da norma. Tradicionalmente
                        utilizam-se dois formatos: com recuo na primeira linha e sem espaço entre os parágrafos ou sem
                        recuo na primeira linha e com espaço de 12pt entre parágrafos, como usado no presente modelo.
                    </P>
                    <P>
                        Cada seção primária deve iniciar em uma nova página. Em editores de texto como o Microfost Word,
                        ao invés de inserir várias quebras de linhas (por exemplo, teclar o Enter várias vezes) o ideal é
                        inserir uma quebra de página (o atalho Ctrl + Enter pode ser utilizado para isso).
                    </P>
                </Page>
            </div>
            <div id='desenvolvimento'>
                <Page showPageNumber={true}>
                    <T1>Desenvolvimento</T1>
                    <P>
                        Os títulos da parte textual devem ser numerados, alinhados à esquerda e destacados de forma que
                        fique clara a estrutura hierárquica do trabalho, conforme NBR 6024 (ABNT, 2012).
                    </P>
                    <P>
                        Abaixo, um exemplo de citação longa. Citações diretas devem ser sempre comentadas, ou seja, não
                        devem apenas serem inseridas no texto sem nenhuma referência direta à mesma (por exemplo, “O autor diz
                        que...”, “Segundo o autor”, “Com base na citação...”). Além disso, devem ser sempre
                        referenciadas com página, sempre que possível. Em caso de documentos digitais, informar a
                        posição, sempre que possível.
                    </P>
                    <Citation>
                        As citações diretas, no texto, com mais de três linhas, devem ser destacadas com recuo de 4 cm
                        da margem esquerda, com letra menor que a do texto utilizado e sem as aspas. No caso de
                        documentos datilografados, deve-se observar apenas o recuo. (ABNT, 2002, p. 2)
                    </Citation>
                    <P>
                        As seções primárias, principalmente as do desenvolvimento, são comumente divididas em subseções.
                        Tais subeseções são chamadas seções secundárias, que podem, por sua vez, serem divididas em
                        seções terciárias e assim sucessivamente, até as seções quinárias. Abaixo, exemplo de formatação
                        que deixa claro a hierarquia das subseções e que exemplifica o sistema de numeração progressiva.
                    </P>
                    <T2>Exemplo de título de seção secundária</T2>
                    <P>
                        Veja, no fim dessa página, um exemplo de nota de rodapé<sup>1</sup>. Note que as notas de
                        rodapé, assim como citações longas, número de páginas, legendas de figuras e tabelas, devem ser
                        em tamanho menor que a fonte do restante do texto. Não há um valor pré-definido, mas
                        tradicionalmente é comum usar 10pt por ser suficientemente menor que 12pt (tamanho utilizado no resto 
                        do texto) para a diferença de tamanho ser facilmente perceptível.
                    </P>
                    <T3>Exemplo de título de seção terciária</T3>
                    <P>Texto da seção terciária.</P>
                    <T4>Exemplo de título de seção quaternária</T4>
                    <P>Texto da seção quaternária.</P>
                    <T5>Exemplo de título de seção quinária</T5>
                    <P>Texto da seção quinária.</P>
                </Page>
            </div>
            <div id='conclusao'>
                <Page showPageNumber={true}>
                    <T1>Considerações finais</T1>
                    <P>
                        Texto das considerações finais.
                    </P>
                    <T2>Limitações e sugestões para pesquisas futuras</T2>
                    <P>
                        Assim como as outras seções da parte textual, pode ser subdividido em outras subseções. A
                        presente subseção, por exemplo, é muito utilizada.
                    </P>
                </Page>
            </div>
            <Referencias/>
            <div id='glossario'>
                <div className='page-info'>
                    <p className='text-center'>Glossário (elemento opcional)</p>
                    <p className='text-justify'>
                        Lista de conceitos em ordem alfabética acompanhados de suas respectivas definições. Quando
                        utilizado, deve ser feito conforme modelo abaixo.
                    </p>
                </div>
                <Page showPageNumber={true}>
                    <Tc>Glossário</Tc>
                    <P>Axônio: Parte do neurônio que conduz impulsos elétricos através da célula.</P>
                    <P>Dentritos: Parte do neurônio que recebe sinais de outros neurônios.</P>
                </Page>
            </div>
            <div id='apendices'>
                <div className='page-info'>
                    <p className='text-center'>Apêndices (elemento opcional)</p>
                    <p className='text-justify'>
                        Os apêndices devem ser identificados com uma letra do alfabeto, conforme modelo abaixo.
                        Apêndices são quaisquer documentos complementares produzidos pelo(a) próprio(a) autor(a) do
                        trabalho.
                    </p>
                </div>
                <Page showPageNumber={true}>
                    <Tc>Apêndice A - Título do primeiro apêndice</Tc>
                </Page>
            </div>
            <div id='anexos'>
                <div className='page-info'>
                    <p className='text-center'>Anexos (elemento opcional)</p>
                    <p className='text-justify'>
                        Assim como os apêndices, os anexos devem ser identificados com uma letra do alfabeto, conforme
                        modelo abaixo. Anexos são quaisquer documentos complementares que não foram produzidos pelo(a)
                        próprio(a) autor(a) do trabalho.
                    </p>
                </div>
                <Page showPageNumber={true}>
                    <Tc>Anexo A - Título do primeiro anexo</Tc>
                </Page>
            </div>
            <div id='indice'>
                <div className='page-info'>
                    <p className='text-center'>Índice (elemento opcional)</p>
                    <p className='text-justify'>
                        Deve ser feito conforme NBR 6034 (ABNT, 2004).
                    </p>
                </div>
                <Page showPageNumber={true}>
                    <Tc>Índice de títulos de elementos obrigatórios</Tc>
                    <P>
                        Resumo, 7<br/>
                        Abstract, 8<br/>
                        Sumário, 13<br/>
                        Introdução, 14<br/>
                        Desenvolvimento, 15<br/>
                        Considerações finais, 16<br/>
                        Referências, 17
                    </P>
                </Page>
            </div>
            <div id='final'>
                <div className='page-info text-center'>
                    <a href='#capa'>Voltar ao início</a>
                </div>
            </div>
        </div>
    );
}

export default App;
