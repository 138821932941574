import * as React from 'react';
import Tc from "./Tc";
import Page from "./Page";
import Info from "./Info";

class Capa extends React.Component<{}, {showInitInfo:boolean, showInfo:boolean}>{
    constructor(props: {}) {
        super(props);

        this.state = {
            showInitInfo: true,
            showInfo: false
        }
    }

    render() {
        return (
            <div id='capa'>
                <div className='page-info'>
                    <p className='text-center'><strong>MODELO INTERATIVO DE TRABALHOS ACADÊMICOS COM NORMAS DA ABNT</strong></p>
                    <p className='text-center'>Criado por <a href='https://davi.solutions' target='_blank' rel='noopener noreferrer'>Davi Alves Oliveira</a>. Última atualização em 14 jul. 2023</p>
                </div>
                <div className='page-info'>
                    <p className='text-center'><strong>Capa</strong> (elemento obrigatório)</p>
                    <p className='text-justify'>
                        A mesma fonte deve ser usada em todo o trabalho (tradicionalmente usa-se Times New Roman ou Arial),
                        com tamanho 12pt, exceto em elementos que devem ter fonte menor. Estes elementos, conforme a NBR 14724, 
                        são "[…] citações com mais de três linhas, notas de rodapé, paginação, dados internacionais de catalogação-na-publicação, 
                        legendas e fontes das ilustrações e das tabelas […]" (ABNT, 2011, p. 10), e tradicionalmente usa-se o tamanho 10pt.
                    </p>
                di</div>
                <Page showPageNumber={false}>
                    <Tc>
                        Nome da instituição<br/>
                        Nome do departamento/setor<br/>
                        Nome do curso/programa
                    </Tc>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>

                    <p className='text-center' onClick={() => this.setState(state => ({showInitInfo: !state.showInitInfo}))}>
                        <Info visibility={this.state.showInitInfo} color='red'>
                            Role a página para ver a formatação de cada elemento.
                            Alguns elementos mostram informações adicionais se você tocar ou clicar neles. Se quiser
                            fazer um teste, toque/clique no título do trabalho logo abaixo.<br/>
                            No celular, você pode usar o movimento de pinça para aumentar e diminuir o zoom.<br/>
                            Clique no 'X' abaixo para fechar essa informação.
                        </Info>
                        Nome completo
                    </p>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>

                    <Tc>Título do trabalho: subtítulo, se houver</Tc>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>

                    <p className='text-center cursor-pointer' onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                        <Info visibility={this.state.showInfo}>
                            Se necessário, adicionar o estado
                        </Info>
                        Cidade<br/>
                        Ano
                    </p>
                </Page>
            </div>
        );
    }
}

export default Capa;