import * as React from 'react';
import Info from "./Info";

class P extends React.Component<{children:any}, {showInfo: boolean}>{
    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false
        }
    }

    render() {
        return (
            <p className='text-justify cursor-pointer' onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Parágrafos: justificado, espaçamento entrelinhas de 1,5pt
                </Info>
                {this.props.children}
            </p>
        );
    }
}

export default P;