import * as React from "react";
import Info from "./Info";
import T1 from "./T1";
import T2 from "./T2";
import T3 from "./T3";

class T4 extends React.Component<{}, {showInfo:boolean, n:number}> {
    static n = 0;

    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false,
            n: T4.n
        };
    }

    componentDidMount(): void {
        this.setState({n: ++T4.n});
    }

    render() {
        return (
            <h1
                className='text-justify font-italic'
                onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Títulos das seções quaternárias: numerados, justificados e itálico
                </Info>
                {T1.n}.{T2.n}.{T3.n}.{this.state.n} {this.props.children}
            </h1>
        )
    }
}

export default T4;