import * as React from 'react';
import Page from "./Page";
import Tc from "./Tc";
import Info from "./Info";

class FolhaDeRosto extends React.Component<{},{showInfo1:boolean, showInfo2:boolean}>{
    constructor(props:{}) {
        super(props);

        this.state = {
            showInfo1: false,
            showInfo2: false
        }
    }

    render() {
        return (
            <div id='folha-de-rosto'>
                <div className='page-info'>
                    <p className='text-center'><strong>Folha de rosto</strong> (elemento obrigatório)</p>
                </div>
                <Page showPageNumber={false}>
                    <p className='text-center'>
                        Nome completo
                    </p>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>

                    <Tc>Título do trabalho: subtítulo, se houver</Tc>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>

                    <p
                        className='nature text-justify cursor-pointer'
                        onClick={() => this.setState((state) => ({showInfo1: !state.showInfo1}))}>

                        <Info visibility={this.state.showInfo1}>
                            A natureza do trabalho deve ser informada com recuo de 8cm
                        </Info>
                        Trabalho submetido à|ao Nome da Instituição como requisito parcial para obtenção do título de
                        Nome do Título.
                    </p>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/>

                    <p className='text-center cursor-pointer' onClick={() => this.setState((state) => ({showInfo2: !state.showInfo2}))}>
                        <Info visibility={this.state.showInfo2}>
                            Se necessário, adicionar o estado
                        </Info>
                        Cidade<br/>
                        Ano
                    </p>
                </Page>
            </div>
        );
    }
}

export default FolhaDeRosto;