import * as React from "react";
import Page from "./Page";
import Tc from "./Tc";
import Info from "./Info";

class FolhaDeAprovacao extends React.Component<{},{showInfo:boolean}>{
    constructor(props:{}) {
        super(props);

        this.state = {
            showInfo: false
        }
    }

    render() {
        return (
            <div id='folha-de-rosto'>
                <div className='page-info'>
                    <p className='text-center'><strong>Folha de aprovação</strong> (elemento obrigatório)</p>
                    <p className='text-justify'>
                        Apesar de listada como elemento obrigatório, é preciso ter em mente o gênero textual do trabalho
                        em questão. A folha de aprovação só é realmente obrigatória quando o trabalho é avaliado por
                        banca, como, por exemplo, TCC de Graduação, Dissertação de Mestrado e Tese de Doutorado.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <p className='text-center'>Nome completo</p>

                    <br/><br/><br/><br/><br/>

                    <Tc>Título do trabalho: subtítulo, se houver</Tc>

                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

                    <p
                        className='text-center cursor-pointer'
                        onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>

                        ____________________________________________________________<br/>
                        Profa. Dra. Nome da orientadora (SIGLA DA INSTITUIÇÃO)<br/>
                        (Orientadora)
                    </p>

                    <br/><br/>

                    <p
                        className='text-center cursor-pointer'
                        onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>

                        <Info visibility={this.state.showInfo}>
                            Informar as siglas das instituições de vínculo de cada professor(a)
                        </Info>
                        ____________________________________________________________<br/>
                        Prof. Dr. Nome do membro (SIGLA DA INSTITUIÇÃO)<br/>
                        (Membro da Banca Examinadora)
                    </p>

                    <br/><br/>

                    <p
                        className='text-center cursor-pointer'
                        onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>

                        ____________________________________________________________<br/>
                        Profa. Dra. Nome do membro (SIGLA DA INSTITUIÇÃO)<br/>
                        (Membro da Banca Examinadora)
                    </p>

                    <br/><br/>

                    <p
                        className='text-center cursor-pointer'
                        onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>

                        ____________________________________________________________<br/>
                        Prof. Me. Nome do membro (SIGLA DA INSTITUIÇÃO)<br/>
                        (Membro da Banca Examinadora)
                    </p>
                </Page>
            </div>
        );
    }
}

export default FolhaDeAprovacao;