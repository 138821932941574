import * as React from "react";
import Page from "./Page";
import Tc from "./Tc";
import Info from "./Info";

class Errata extends React.Component<{}, {showInfo: boolean}>{
    constructor(props:{}) {
        super(props);

        this.state = {
            showInfo: false
        }
    }

    render() {
        return (
            <div id='errata'>
                <div className='page-info'>
                    <p className='text-center'>Errata (elemento opcional)</p>
                    <p className='text-justify'>
                        Se algum erro for cometido após publicação do trabalho, é aqui que o erro deve ser corrigido,
                        conforme modelo.
                    </p>
                </div>
                <Page showPageNumber={false}>
                    <Tc>Errata</Tc>
                    <p className='reference cursor-pointer' onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                        <Info visibility={this.state.showInfo}>
                            Após o título, deve constar a referência completa do trabalho
                        </Info>
                        SOBRENOME, N. <strong>Título do trabalho</strong>. Ano de defesa ou de apresentação. Número de folhas.
                        Natureza (e.g. Tese, Dissertação, etc.). Programa ou Departamento, Universidade, Cidade, Ano de publicação.
                    </p>
                    <table className='errata text-center mx-auto'>
                        <thead>
                        <tr>
                            <th>Folha</th>
                            <th>Linha</th>
                            <th>Onde se lê</th>
                            <th>Leia-se</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>número da página</td>
                            <td>número da linha</td>
                            <td>erro</td>
                            <td>correção</td>
                        </tr>
                        </tbody>
                    </table>
                </Page>
            </div>
        );
    }
}

export default Errata;