import * as React from "react";
import CloseButton from "./CloseButton";

class Menu extends React.Component<{}, {visible:boolean}>{
    constructor(props:{}) {
        super(props);

        this.state = {
            visible: false
        }
    }

    render() {
        return(
            this.state.visible ?
                <menu className='visible' onClick={() => this.setState({visible: false})}>
                    <CloseButton/>
                    <li><a href="#capa" title='Ir para: Capa (obrigatório)'><strong>Capa</strong></a></li>
                    <li><a href="#folha-de-rosto" title='Ir para: Folha de Rosto (obrigatório)'><strong>Folha de Rosto</strong></a></li>
                    <li><a href="#errata" title='Ir para: Errata (opcional)'>Errata</a></li>
                    <li><a href="#folha-de-aprovacao" title='Ir para: Folha de Aprovação (obrigatório)'><strong>Folha de Aprovação</strong></a></li>
                    <li><a href="#dedicatoria" title='Ir para: Dedicatória (opcional)'>Dedicatória</a></li>
                    <li><a href="#agradecimentos" title='Ir para: Agradecimentos (opcional)'>Agradecimentos</a></li>
                    <li><a href="#epigrafe" title='Ir para: Epígrafe (opcional)'>Epígrafe</a></li>
                    <li><a href="#resumo" title='Ir para: Resumo (obrigatório)'><strong>Resumo</strong></a></li>
                    <li><a href="#abstract" title='Ir para: Resumo em língua estrangeira (obrigatório)'><strong>Resumo em língua estrangeira</strong></a></li>
                    <li><a href="#lista-de-figuras" title='Ir para: Lista de figuras (opcional)'>Lista de figuras</a></li>
                    <li><a href="#lista-de-tabelas" title='Ir para: Lista de tabelas (opcional)'>Lista de tabelas</a></li>
                    <li><a href="#lista-de-abreviaturas-e-siglas" title='Ir para: Lista de abreviaturas e siglas (opcional)'>Lista de abreviaturas e siglas</a></li>
                    <li><a href="#lista-de-simbolos" title='Ir para: Lista de símbolos (opcional)'>Lista de símbolos</a></li>
                    <li><a href="#sumario" title='Ir para: Sumário (obrigatório)'><strong>Sumário</strong></a></li>
                    <li><a href="#introducao" title='Ir para: Introdução (obrigatório)'><strong>Introdução</strong></a></li>
                    <li><a href="#desenvolvimento" title='Ir para: Desenvolvimento (obrigatório)'><strong>Desenvolvimento</strong></a></li>
                    <li><a href="#conclusao" title='Ir para: Conclusão (obrigatório)'><strong>Conclusão</strong></a></li>
                    <li><a href="#referencias" title='Ir para: Referências (obrigatório)'><strong>Referências</strong></a></li>
                    <li><a href="#glossario" title='Ir para: Glossário (opcional)'>Glossário</a></li>
                    <li><a href="#apendices" title='Ir para: Apêndices (opcional)'>Apêndices</a></li>
                    <li><a href="#anexos" title='Ir para: Anexos (opcional)'>Anexos</a></li>
                    <li><a href="#indice" title='Ir para: Índice (opcional)'>Índice</a></li>
                </menu>         :
                <menu className='collapsed' onClick={() => this.setState({visible: true})}>
                    <svg viewBox="0 0 100 80" width="20" height="20">
                        <rect width="100" height="20"/>
                        <rect y="30" width="100" height="20"/>
                        <rect y="60" width="100" height="20"/>
                    </svg>
                </menu>
        );
    }
}

export default Menu;