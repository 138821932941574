import * as React from "react";
import Info from "./Info";

class Tc extends React.Component<{}, {showInfo:boolean}> {
    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false
        };
    }

    render() {
        return (
            <h1
                className='text-center text-uppercase font-weight-bold'
                onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Títulos das partes pré-textual e pós-textual: sem numeração, centralizados, caixa alta e negrito
                </Info>
                {this.props.children}
            </h1>
        )
    }
}

export default Tc;