import * as React from "react";
import Info from "./Info";

class T1 extends React.Component<{}, {showInfo:boolean, n:number}> {
    static n = 0;

    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false,
            n: T1.n
        };
    }

    componentDidMount(): void {
        this.setState({n: ++T1.n});
    }

    render() {
        return (
            <h1
                className='text-justify text-uppercase font-weight-bold'
                onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Títulos das seções primárias: numerados, justificados, caixa alta e negrito
                </Info>
                {this.state.n} {this.props.children}
            </h1>
        )
    }
}

export default T1;