import * as React from 'react';
import Page from "./Page";
import Tc from "./Tc";
import Info from "./Info";

class Referencias extends React.Component<{}, {
    showInfo1:boolean,
    showInfo2:boolean,
    showInfo3:boolean,
    showInfo4:boolean,
    showInfo5:boolean,
    showInfo6:boolean,
    showInfo7:boolean
}>{

    constructor(props:{}) {
        super(props);

        this.state = {
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            showInfo4: false,
            showInfo5: false,
            showInfo6: false,
            showInfo7: false
        }
    }

    render() {
        return (
            <div id='referencias'>
                <div className='page-info'>
                    <p className='text-center'>
                        <strong>Referências</strong> (elemento obrigatório)
                    </p>
                    <p className='text-justify'>
                        A lista de referências deve ser feita conforme NBR 6023 (ABNT, 2018). Recomenda-se o uso de um
                        gerenciador como o <a href='https://www.mendeley.com/' title='Ir para: página inicial do Mendeley'>Mendeley</a> ou
                        o <a href='https://www.zotero.org/' title='Ir para: página inicial do Zotero'>Zotero</a> para
                        facilitar o gerenciamento de referências. Abaixo, alguns exemplos mais utilizados de
                        referências. A NBR 6023 traz uma lista mais completa contendo vários exemplos.
                    </p>
                </div>
                <Page showPageNumber={true}>
                    <Tc>Referências</Tc>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo1: !state.showInfo1}))}>
                        <Info visibility={this.state.showInfo1}>
                            Normas utilizadas no presente modelo.
                        </Info>

                        ABNT. <strong>ABNT NBR 14724</strong>: Informação e documentação — Trabalhos acadêmicos —
                        Apresentação. 3.
                        ed. Rio de Janeiro: ABNT, 2011.
                        <br/><br/>

                        ABNT. <strong>ABNT NBR 6023</strong>: Informação e documentação — Referências — Elaboração. 2.
                        ed. Rio de
                        Janeiro: ABNT, 2018.
                        <br/><br/>

                        ABNT. <strong>ABNT NBR 6024</strong>: Informação e documentação — Numeração progressiva das
                        seções de um
                        documento — Apresentação. 2. ed. Rio de Janeiro: ABNT, 2012.
                        <br/><br/>

                        ABNT. <strong>ABNT NBR 6027</strong>: Informação e documentação — Sumário —
                        Apresentação. 2. ed. Rio de
                        Janeiro: ABNT, 2012.
                        <br/><br/>

                        ABNT. <strong>ABNT NBR 6034</strong>: Informação e documentação - Índice -
                        Apresentação. 2. ed. Rio de
                        Janeiro: ABNT, 2004.
                        <br/><br/>

                        ABNT. <strong>NBR 10520</strong>: Informação e documentação - Citações em
                        documentos - Apresentação. Rio de
                        Janeiro: ABNT, 2002.
                        <br/><br/>

                        ABNT. <strong>NBR 6028</strong>: Informação e documentação - Resumo -
                        Apresentação. 2. ed. Rio de Janeiro: ABNT,
                        2021.
                    </p>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo2: !state.showInfo2}))}>
                        <Info visibility={this.state.showInfo2}>
                            Website. Neste exemplo, não está disponível o nome do(a) autor(a),
                            por isso a referência é iniciada com o título da página, com a primeira palavra em caixa
                            alta e o restante normal. As informações entre colchetes são aquelas que não constam no
                            documento mas que podem ser conhecidas por outros meios.
                        </Info>

                        KONAMI Code. <em>In</em>: WIKIPEDIA: the free encyclopedia. [San Francisco, CA: Wikimedia
                        Foundation, 2010]. Disponível em: <a href="https://en.wikipedia.org/wiki/Konami_Code"
                           title="Ir para a página da Wikipedia: Konami code" target="_blank" rel="noopener noreferrer">
                            https://en.wikipedia.org/wiki/Konami_Code.</a> Acesso em: 12 abr. 2020.
                    </p>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo3: !state.showInfo3}))}>
                        <Info visibility={this.state.showInfo3}>
                            Livro. Se for primeira edição, não precisa informar. A partir da segunda
                            edição, informar conforme modelo.
                        </Info>

                        SOBRENOME, Nome. <strong>Título do livro</strong>: subtítulo, se houver. 2. ed. Cidade: Editora,
                        Ano.
                    </p>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo4: !state.showInfo4}))}>
                        <Info visibility={this.state.showInfo4}>
                            Capítulo de livro. A partir da atualização de 2018, caso o autor do capítulo
                            seja o mesmo do livro, deve-se repetir o nome e não usar '______'. Note que o destaque (negrito) é no
                            título do livro. Ao final deve-se indicar o intervalo de páginas em que o capítulo se encontra.
                        </Info>

                        SOBRENOME, Nome da Autora do Capítulo. Título do capítulo: subtítulo do capítulo, se houver.
                        <em>In</em>: SOBRENOME, Nome da Autora do Livro. <strong>Título do livro</strong>: subtítulo, se
                        houver. 3. ed. Cidade: Editora, Ano. p. xx-yy.
                    </p>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo5: !state.showInfo5}))}>
                        <Info visibility={this.state.showInfo5}>
                            Artigo em periódico. Note que o destaque (negrito) é no título do artigo.
                            Indicar volume, número, páginas e ano. Sempre que disponível, informar o DOI e o link completo do trabalho.
                            A data de acesso deve seguir o modelo dia, mês abreviado e ano, como em '8 abr. 2020'. A abreviação
                            consiste nas três primeiras letras do mês seguidas por ponto, com excessão de maio, que não deve ser
                            abreviado.
                        </Info>

                        SOBRENOME, Nome da Primeira autora; SOBRENOME, Nome do Segundo Autor. Título do artigo:
                        subtítulo, se houver.
                        <strong>Nome do Periódico</strong>, Cidade, v. x, n. y, p. z-a, Ano. DOI ############.
                        Disponível em:
                        http://endereco.do.artigo/completo.pdf. Acesso em: dia mês ano.
                    </p>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo6: !state.showInfo6}))}>
                        <Info visibility={this.state.showInfo6}>
                            Trabalho publicado em anais de eventos. Note que o destaque é caixa alta e não negrito, e é
                            no título do evento. Indicar edição é número (e.g. 3). Sempre que disponível, informar o link completo
                            do trabalho e, nesse caso, a data de acesso.
                        </Info>

                        SOBRENOME, N.; Título do trabalho: subtítulo, se houver. <em>In</em>: TÍTULO DO EVENTO, edição.,
                        ano de acontecimento do evento, Cidade de acontecimento do evento. <strong>Anais</strong> [...].
                        Cidade da instituição promotora: Instituição promotora, ano de publicação do trabalho. p. x-y.
                    </p>

                    <p className="reference" onClick={() => this.setState(state => ({showInfo7: !state.showInfo7}))}>
                        <Info visibility={this.state.showInfo7}>
                            Vídeo no youtube. Neste exemplo, não está disponível o nome d@ autor(a), por
                            isso a referência é iniciada com o título do vídeo, com a primeira palavra em caixa alta e o restante
                            normal. Perceba também que são utilizadas as expressões [<em>S. l.</em>] e
                            [<em>S. n.</em>] (<em>sine loco</em> e <em>sine nomine</em>, respectivamente) para indicar que não há
                            informação de local e editora, o que vale para qualquer tipo de trabalho e não apenas vídeos. Também
                            pode ser utilizado [S. d.] quando não há informção de data de publicação.
                        </Info>

                        TÍTULO do vídeo. [<em>S. l.</em>: <em>s. n.</em>], ano de publicação. 1 vídeo (duração do
                        vídeo).
                        Publicado pelo canal Nome do Canal. Disponível em: http://endereco.completo.do/video. Acesso em:
                        dia mês ano.
                    </p>
                </Page>
            </div>
        );
    }
}

export default Referencias;