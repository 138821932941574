import * as React from "react";
import CloseButton from "./CloseButton";

class Info extends React.Component<{children:any, visibility:boolean, color?:'red'},{visibility:boolean}> {
    constructor(props:{children:any, visibility:boolean}) {
        super(props);

        this.state = {
            visibility: props.visibility
        }
    }

    componentDidUpdate(
        prevProps: Readonly<{ children: any; visibility: boolean }>,
        prevState: Readonly<{ visibility: boolean }>) {

        if (this.state.visibility !== this.props.visibility) {
            this.setState({visibility: this.props.visibility});
        }
    }

    render() {
        return (
            this.state.visibility &&
            <span className='info-container'>
                <span className={`info font-weight-normal ${this.props.color === 'red' ? 'red' : ''}`}>
                    {this.props.children}
                    <CloseButton/>
                </span>
            </span>
        );
    }
}

export default Info;