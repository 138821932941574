import * as React from "react";
import Info from "./Info";
import T1 from "./T1";

class T2 extends React.Component<{}, {showInfo:boolean, n:number}> {
    static n = 0;

    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false,
            n: T2.n
        };
    }

    componentDidMount(): void {
        this.setState({n: ++T2.n});
    }

    render() {
        return (
            <h1
                className='text-justify text-uppercase'
                onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Títulos das seções secundárias: numerados, justificados, caixa alta
                </Info>
                {T1.n}.{this.state.n} {this.props.children}
            </h1>
        )
    }
}

export default T2;