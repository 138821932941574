import * as React from "react";

class Page extends React.Component<{showPageNumber:boolean, children:any}, {pageNumber:number}>{
    static numberOfPages = 0;

    constructor(props: {showPageNumber:boolean, children:any}) {
        super(props);

        this.state = {
            pageNumber: Page.numberOfPages
        }
    }

    componentDidMount() {
        this.setState({pageNumber: Page.numberOfPages});
        Page.numberOfPages++;
    }

    render() {
        return (
            <div className='page'>
                <div className='margin margin-top'>Margem superior: 3cm</div>
                <div className='margin margin-right'>Margem direita: 2cm</div>
                <div className='margin margin-bottom'>Margem inferior: 2cm</div>
                <div className='margin margin-left'>Margem esquerda: 3cm</div>
                <p className='page-number'>{this.props.showPageNumber && this.state.pageNumber}</p>
                <div className='center'>{this.props.children}</div>
            </div>
        );
    }
}

export default Page;