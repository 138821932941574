import * as React from "react";
import Info from "./Info";

class Citation extends React.Component<{children:any},{showInfo:boolean}> {
    constructor(props:{children:any}) {
        super(props);

        this.state = {
            showInfo: false
        }
    }

    render() {
        return (
            <p className='citation cursor-pointer' onClick={() => this.setState((state) => ({showInfo: !state.showInfo}))}>
                <Info visibility={this.state.showInfo}>
                    Citações longas devem ter recuo esquerdo de 4cm, espaçamento simples, alinhamento justificado,
                    fonte menor que o restante do texto (por exemplo, 10pt) e não deve estar entre aspas
                </Info>

                {this.props.children}
            </p>
        );
    }
}

export default Citation;